<template>
  <div class="land-trust">
    <div class="quick-banner">
      <p>农技服务</p>
      <img src="../../assets/imgs/banner2.png" alt="" />
    </div>
    <div class="land-main">
      <div class="new-list">
        <div class="top-btn">
          <div
            class="supply-btn pointer"
            :class="this.currentCode == 0 ? 'active' : null"
            @click="supplyHandle(0)"
          >
            <div class="top zj-bg"></div>
            <div class="end">专家名录</div>
          </div>
          <div
            class="supply-btn pointer"
            :class="this.currentCode == 1 ? 'active' : null"
            @click="supplyHandle(1)"
          >
            <div class="top nj-bg"></div>
            <div class="end">农技服务队</div>
          </div>
          <div
            class="supply-btn pointer"
            :class="this.currentCode == 2 ? 'active' : null"
            @click="supplyHandle(2)"
          >
            <div class="top zd-bg"></div>
            <div class="end">远程诊断</div>
          </div>
        </div>
        <div class="new-des">
          <ul v-if="currentCode == 0">
            <li v-for="(item, i) in tableData" :key="i">
              <div class="img">
                <img src="../../assets/titleImg.png" />
              </div>
              <div class="des">
                <div class="des-info">
                  <h4>{{ item.name }}</h4>
                  <div>联系地址:{{ item.address }}</div>
                </div>
                <div class="des-info">
                  <div>专家编号:{{ item.expertCode }}</div>
                  <div>联系方式:{{ item.phone }}</div>
                </div>
              </div>
            </li>
          </ul>
          <ul v-if="currentCode == 1">
            <li v-for="(item, i) in tableData" :key="i">
              <div class="img">
                <img src="../../assets/titleImg.png" />
              </div>
              <div class="des">
                <div class="des">
                  <div class="des-info">
                    <h4>{{ item.agriculturaName }}</h4>
                    <div>联系地址:{{ item.address }}</div>
                  </div>
                  <div class="des-info">
                    <div></div>
                    <div>联系方式:{{ item.phone }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul v-if="currentCode == 2 && isLogin">
            <li>
              <el-button plain @click="handleClickYCZF">远程诊断</el-button>
            </li>
            <li v-for="(item, i) in tableData" :key="i">
              <div class="img">
                <img src="../../assets/titleImg.png" />
              </div>
              <div class="des">
                <div class="des">
                  <div class="des-info">
                    <h4>{{ item.consultTitle }}</h4>
                    <div>描述:{{ item.consultDesc }}</div>
                  </div>
                  <div class="des-info">
                    <div>时间:{{ item.updateTime }}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="pages">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="prev, pager, next, sizes, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- <card title="实时定位">
        <div class="map-area">
          <baiduMap :projectList="list" ref="bmMap" />
        </div>
      </card> -->
    </div>
    <el-dialog
      title="远程诊断"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      :destroy-on-close="true"
    >
      <div class="zdStyle">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="种植作物:">
            <el-input
              v-model="form.consultTitle"
              placeholder="请填写种植作物"
            ></el-input>
          </el-form-item>
          <el-form-item label="病虫害描述:">
            <el-input
              type="textarea"
              v-model="form.consultDesc"
              placeholder="请填写作物病虫害描述"
            ></el-input>
          </el-form-item>
          <el-form-item label="附件:" class="fileUpStyle">
            <el-upload
              ref="upload"
              class="upload-demo"
              :limit="6"
              :on-remove="handleRemoveClick"
              :show-file-list="true"
              action="#"
              :http-request="handleChange"
              :before-upload="beforeUpload"
              list-type="picture-card"
            >
              <el-button type="primary">上传附件</el-button>
              <div slot="tip" class="el-upload__tip">
                单张图片最大6M，最多上传6张，支持：JPG/JPEG/GIF/PNG格式
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from "@/js/utils/store";
import Card from "@/components/Card/index.vue";
import BaiduMap from "../productionService/components/landTrustSubpage/baiduMap.vue";
import {
  getSupplyInfoOfTechnology,
  getExpertInfo,
  getZXInfo,
  uploadZXFile,
  addZX,
} from "@/api/supplyDemand.js";
const newTab = [
  {
    code: 0,
    name: "专家名录",
  },
  {
    code: 1,
    name: "农技服务队",
  },
  {
    code: 2,
    name: "远程诊断",
  },
];

export default {
  components: {
    Card,
    BaiduMap,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 5,
      newTab,
      currentCode: 0,
      keyword: "",
      tableData: [],
      form: {
        consultTitle: null,
        consultDesc: null,
        consultFileList: [],
        isReply: 1,
      },
      list: [],
      dialogVisible: false,
      isLogin: false,
    };
  },
  created() {
    this.getExpertData();
  },
  mounted() {
    this.getList();
  },
  methods: {
    //获取专家信息接口
    async getExpertData() {
      let params = {
        current: this.currentPage,
        size: this.pageSize,
      };
      let res = await getExpertInfo(params);
      if (!res || res.code != 0) {
        return;
      }
      this.tableData = res.data.records;
      this.total = res.data.total;
    },
    //获取农技服务队接口
    async getNJFWD() {
      let params = {
        pnum: this.currentPage,
        psize: this.pageSize,
      };
      let res = await getSupplyInfoOfTechnology(params);
      if (res.dataNum > 0) {
        this.tableData = res.data;
        this.total = res.dataNum;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
    //获取地图上图的农技服务点接口
    async getList() {
      let params = {
        pnum: this.currentPage,
        psize: 100,
      };
      let res = await getSupplyInfoOfTechnology(params);
      if (!res || res.code != 0) {
        return;
      }
      let listData = res.data.records;
      listData.forEach((item) => {
        this.list.push({
          id: null,
          name: item.agriculturaName,
          lot: item.longitude,
          lat: item.latitude,
        });
      });
    },
    //获取远程诊断列表接口
    async getZXList() {
      let params = {
        current: this.currentPage,
        size: this.pageSize,
      };
      let res = await getZXInfo(params);
      if (res.code == 0 && res.data.total > 0) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    //添加远程诊断咨询信息
    async addYCZD(params) {
      let res = await addZX(params);
      if (!res || res.code != 0) {
        return;
      }
      this.getZXList();
      this.handleClose();
    },
    //上传远程诊断咨询文件接口
    async uploadLoadFiles(file) {
      var type = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);
      var formData = new FormData();
      formData.append("files", this.file);
      uploadZXFile(formData)
        .then((res) => {
          // 有问题
          if (res && res.code == 0) {
            let name = res.data[0].fileName;
            let url = res.data[0].url;
            let ysm = res.data[0].originName;
            this.form.consultFileList.push({
              fileName: name,
              fileUrl: url,
              fileType: type,
              originName: ysm,
            });
          }
        })
        .catch((err) => {
          let uid = file.uid; // 关键作用代码，去除文件列表失败文件
          let idx = this.$refs.upload.upload.findIndex(
            (item) => item.uid === uid
          ); // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          this.$refs.upload.uploadFiles.splice(idx, 1); // 关键作用代码，去除文件列表失败文件
        });
    },
    //点击专家名录/农技服务队/远程诊断切换按钮
    supplyHandle(val) {
      this.currentCode = val;
      this.operationSelect();
    },
    //每页数量切换
    handleSizeChange(val) {
      this.pageSize = val;
      this.operationSelect();
    },
    //当前页切换
    handleCurrentChange(val) {
      this.currentPage = val;
      this.operationSelect();
    },
    //判断页面获取数据
    operationSelect() {
      switch (this.currentCode) {
        //专家名录
        case 0:
          this.getExpertData();
          break;
        //农技服务队
        case 1:
          this.getNJFWD();
          break;
        //远程诊断
        case 2:
          let token = getStore({ name: "access_token" });
          if (token) {
            this.isLogin = true;
            this.getZXList();
          } else {
            this.$confirm(
              "您需要登陆才可进行远程诊断，是否跳转到登陆页?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                localStorage.setItem("backPath", this.$route.fullPath);
                this.$router.push({ name: "login" });
                this.isLogin = true;
              })
              .catch(() => {
                this.isLogin = false;
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
          break;
        default:
          break;
      }
    },


    //请求上传文件
    handleChange(file) {
      this.uploadLoadFiles(file);
    },
    //上传文件前判断类型
    beforeUpload(file) {
      let size = 6 * 1024 * 1024;
      if (file.size > size) {
        this.$message.warning("上传图片不能超过6M！");
        this.$refs.upload.abort();
        return false;
      }
      this.file = file;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        testmsg == "jpg" ||
        testmsg == "jpeg" ||
        testmsg == "gif" ||
        testmsg == "png"
      ) {
      } else {
        this.$message.warning(
          "上传失败，当前只能上传JPG、JPEG、GIF、PNG格式文件！"
        );
        this.$refs.upload.abort();
        return false;
      }
    },
    //移除文件
    handleRemoveClick(file, fileList) {
      let sz = [];
      this.form.consultFileList.forEach((item) => {
        if (item.originName != file.name) {
          sz.push({
            fileName: item.fileName,
            fileUrl: item.fileUrl,
            fileType: item.fileType,
          });
        }
      });
      this.form.consultFileList = sz;
    },
    //提交远程诊断新增信息
    onSubmit() {
      if (this.form.consultTitle == null || this.form.consultTitle == "") {
        this.$message({
          message: "标题不能为空,请输入!",
          type: "warning",
        });
        return;
      }
      if (this.form.consultDesc == null || this.form.consultDesc == "") {
        this.$message({
          message: "病虫害描述不能为空,请输入!",
          type: "warning",
        });
        return;
      }
      this.addYCZD(this.form);
    },
    //打开远程诊断对话框
    handleClickYCZF() {
      this.dialogVisible = true;
    },
    //关闭对话框
    handleClose() {
      this.dialogVisible = false;
      this.form = {
        consultTitle: null,
        consultDesc: null,
        consultFileList: [],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.land-trust {
  .quick-banner {
    width: 1920px;
    margin: 0 auto;
    position: relative;
    height: 180px;
    overflow: hidden;
    p {
          position: absolute;
          top: 40px;
      left: 20%;
      // transform: translate(-50%, -50%);
      font-size: 32px;
      color: #ffffff;
    }
  }

  background: #fff;
  li {
    list-style: none;
  }

  .zdStyle {
    text-align: left;

    .el-upload__tip {
      font-size: 16px;
    }

    /deep/ .el-form-item__label {
      font-size: 16px;
    }

    // .fileUpStyle{
    //   width: 55%;
    // }
  }
}
.swiper {
  width: 100%;
  height: 700px;
  text-align: center;
  background: #fff;
  position: relative;
  .carousel {
    height: 700px;
    width: 100%;
  }
  /deep/ .el-carousel__container {
    height: 100%;
  }
}

.land-main {
  width: 1472px;
  margin: 0 auto;

  .new-list {
    margin-top: 20px;
    .zj-bg {
      background: url("../../assets/icon-zj-d-1.png") no-repeat center center;
    }
    .nj-bg {
      background: url("../../assets/icon-nj-d-1.png") no-repeat center center;
    }
    .zd-bg {
      background: url("../../assets/icon-zd-d-1.png") no-repeat center center;
    }
    .top-btn {
      padding: 20px 0px;
      display: flex;

      > div {
        flex: 1;
        background: #fafafa;
        border: 1px solid #e5e5e5;
        margin: 0px 10px;
        height: 320px;

        &:hover {
          transition: 0.3s;
          background: #0668b3;
          color: #fff;
          .zj-bg {
            background: url("../../assets/icon-zj-a-1.png") no-repeat center
              center;
          }
          .nj-bg {
            background: url("../../assets/icon-nj-a-1.png") no-repeat center
              center;
          }
          .zd-bg {
            background: url("../../assets/icon-zd-a-1.png") no-repeat center
              center;
          }
        }
      }

      > div.active {
        transition: 0.3s;
        background: #0668b3;
        color: #fff;
        .zj-bg {
          background: url("../../assets/icon-zj-a-1.png") no-repeat center
            center;
        }
        .nj-bg {
          background: url("../../assets/icon-nj-a-1.png") no-repeat center
            center;
        }
        .zd-bg {
          background: url("../../assets/icon-zd-a-1.png") no-repeat center
            center;
        }
      }

      .pointer {
        cursor: pointer;
      }

      .supply-btn {
        .top {
          padding-top: 60px;
          text-align: center;
          height: 190px;
          border-bottom: 1px solid #dcdcdc;
        }
        .end {
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
      }
    }
    .news-tab {
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      position: relative;

      .input-with-select {
        position: absolute;
        right: 0;
        top: -10px;
        width: auto;
      }
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
    }

    .new-des {
      ul,
      li {
        padding: 0;
        margin: 0;
      }

      ul {
        padding-top: 20px;
      }
      ul li {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px dashed #dcdcdc;

        .img {
          img {
            width: 140px;
            height: auto;
          }
        }
        .des {
          padding-left: 20px;
          text-align: left;

          display: flex;

          h4 {
            font-size: 24px;
            padding: 0;
            margin: 0;
          }

          &-info {
            display: grid;
            grid-template-rows: 1fr 1fr;
            margin-right: 24px;

            align-items: center;
          }
        }
      }
    }
  }

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .news-des {
    margin-bottom: 20px;
  }

  .map-area {
    margin: 20px 0;
    height: 500px;
  }
}
</style>

<style lang="less">
.land-trust .el-table th {
  background: #0668b3;
  color: #fff;
}
</style>
